<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Strengthens Immuno-Oncology Expertise with Two New Advisors
      </div>
      <div class="time">
        Oct 3, 2023
      </div>
      <div class="content">
        
        <p>
          Cambridge, Mass. - October 3, 2023 - GV20 Therapeutics, a clinical-stage biotechnology company  integrating AI, genomics, and cancer biology to create revolutionary antibody therapeutics, today announced the appointment of two new advisors - Dr. James Wooldridge and Dr. Lewis Lanier.
        </p>
        <p>
          Dr. Wooldridge is a seasoned expert in oncology drug development, having previously served in executive roles at Checkmate Pharmaceuticals, Aeglea BioTherapeutics, and Eli Lilly. He brings over 20 years of experience guiding cancer therapy research and clinical trials across industry and academia.
        </p>
        <p>
          Dr. Lanier is a world-renowned immunologist and Professor Emeritus at the University of California, San Francisco. He is an elected member of the National Academy of Sciences known for his pioneering research on Natural Killer cells. Dr. Lanier has received numerous honors for his contributions to tumor immunology.
        </p>
        <p>
          "We are thrilled to be joined by Drs. Wooldridge and Lanier, two luminaries in the fields of immuno-oncology and tumor immunology," said Dr. Shirley Liu, CEO of GV20 Therapeutics. "Their collective expertise will be invaluable as we advance our pipeline of novel therapies ."
        </p>
        <p>
          GV20 Therapeutics is developing first-in-class cancer therapeutics that stimulate and direct a patient's immune system to recognize and eliminate cancer cells. The company's pipeline programs are based on its proprietary STEAD platform which combines powerful AI, functional genomics, big data, and deep biological insights to identify novel targets and therapeutic antibodies.
        </p>
        <p>
          "I'm impressed by GV20's innovative approach to engaging the immune system in the fight against cancer," commented Dr. Wooldridge. "I look forward to working with the team and helping drive these novel therapies through development."
        </p>
        <p>
          Dr. Lanier remarked, "Harnessing the power of dendritic cells and natural killer cells is an exciting new frontier in immuno-oncology. I'm eager to collaborate with GV20 as they work to translate this science into transformative medicines for patients." 
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          GV20 Therapeutics is a biotechnology company with sites in Cambridge, Massachusetts and Shanghai, China. GV20 uses high-throughput functional genomics and artificial intelligence to identify novel cancer drug targets and antibody therapeutics. The company’s pipeline includes GV20-0251, a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody against a novel immune checkpoint IGSF8, which is currently being evaluated in a Phase 1 clinical study (ClinicalTrials.gov Identifier: NCT05669430). “GV20” references one of the most important acupoints for the human body, symbolizing the company’s mission to find and exploit vulnerabilities in cancer biology. 
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://mobile.gv20tx.com/">https://gv20tx.com/</a> and follow the company on <a style="color:#2d8cf0;" href="https://www.linkedin.com/company/gv20tx/">LinkedIn</a>.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
